import React, { useState } from "react";
import { Header, Sidebar } from "../components";
import "./pages.scss";
import { changePassword } from "../Redux/Action/authAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../services/Loading";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";
const ChangePassword = () => {
  const {  loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [formdata, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formdata, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formdata.current_password) {
      errors.current_password = "Current password is required";
    }

     if (!formdata.new_password) {
      errors.new_password = "New password is required";
    }

    if (!formdata.confirm_password) {
      errors.confirm_password = "Confirm password is required.";
    } else if (formdata.new_password !== formdata.confirm_password) {
      errors.confirm_password = "Password do not match.";
    }

    setErrors(errors);


    if (Object.keys(errors).length === 0) {
      dispatch(changePassword({ formdata , setFormData }));
    }
  };

  return (
    <>
     <PageTitleHelmet />
    <div className="Change-Password-page">
      <div className="header-pages">
        <Header />
      </div>

      <div className="container">
        <div className="slide-bar-grid">
          <div className="left-bar-data">
            <Sidebar />
          </div>
          <div className="right-bar-data">
          <Loading isLoading={loading}>
            <div className="right-side-bg">
              <div className="header-top">
                <h2>Change Password</h2>
              </div>
              <form action="" onSubmit={handleSubmit}>
                <div className="all-input-inner">
                  <div className="inputall_main">
                    <label htmlFor="">Current Password</label>
                    <input
                      type="text"
                      name="current_password"
                      placeholder="Enter your current password"
                      value={formdata.current_password}
                      onChange={handleInputChange}
                    />

                    {errors.current_password && (
                      <div className="error-message">
                        {errors.current_password}
                      </div>
                    )}
                  </div>
                  <div className="inputall_main">
                    <label htmlFor="">New Password</label>
                    <input
                      type="text"
                      placeholder="Enter new password"
                      name="new_password"
                      value={formdata.new_password}
                      onChange={handleInputChange}
                    />

                    {errors.new_password && (
                      <div className="error-message">{errors.new_password}</div>
                    )}
                  </div>
                  <div className="inputall_main">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="text"
                      placeholder="Enter your confirm password"
                      name="confirm_password"
                      value={formdata.confirm_password}
                      onChange={handleInputChange}
                    />

                    {errors.confirm_password && (
                      <div className="error-message">
                        {errors.confirm_password}
                      </div>
                    )}
                  </div>

                  <div className="password-update-btn">
                    <button to="" className="btn-common">
                      {" "}
                      Update password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Loading>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ChangePassword;
