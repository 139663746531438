import React, { useEffect, useRef } from "react";
import { Header, LoadingBar, Sidebar } from "../components";
import { images } from "../actions/customFn";
import { useGetPurchasedPassDetailsMutation } from "../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo } from "../services/customeFn";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintPass from "../components/PrintPass";
import { PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
const ViewPassDetail = () => {
  const { id } = useParams();
  const { isAuthenticated, customerDetails } = useSelector(
    (state) => state.auth
  );
  const { customer_id, password, type } = getCustomerInfo();
  const [getPurchasedPassDetails, { data, error: buyPasserror, isLoading }] =
    useGetPurchasedPassDetailsMutation();

  useEffect(() => {
    getPurchasedPassDetails({ customer_id, password, id });
  }, [id]);

  const BuyPassDetails = data?.data || null;

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Pass Details",
  });

 

  return (
    <div className="Change-Password-page">
      <div className="header-pages">
        <Header />
      </div>

      <div className="container">
        <div className="slide-bar-grid">
          <div className="left-bar-data">
            <Sidebar />
          </div>
          <div className="right-bar-data">
            {isLoading || !BuyPassDetails ? (
              <LoadingBar />
            ) : (
              <div className="right-side-bg right-passdetail-space">
                <div className="viewdetailpassflex">
                  <div className="passdetail_leftcontent">
                    <div className="header-top">
                      <h2>Pass Details</h2>
                    </div>

                    {/* Hidden Print Content */}
                    <div ref={printRef} className="print-only">
                      <PrintPass
                        BuyPassDetails = {BuyPassDetails}
                      />
                    </div>

                    <div className="viewpass_detailsection">
                      <div className="header-top">
                        <h2>{BuyPassDetails?.title}</h2>
                      </div>
                      <p>{BuyPassDetails?.description}</p>

                      <div className="detail_pricelists">
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Booking ID : </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.order_id}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="detail_pricelists">
                        {BuyPassDetails?.customer_name && (
                          <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>Customer</p>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>
                                <span>{BuyPassDetails?.customer_name}</span>
                              </p>
                            </div>
                          </div>
                        )}

                        {BuyPassDetails?.email && customerDetails?.type == "Vendor" && (
                          <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>Email</p>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>
                                <span>{BuyPassDetails?.email}</span>
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Price</p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>${BuyPassDetails?.price}</span>
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Start date </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.startdate}</span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>End date </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.enddate}</span>
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Tracking Type </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.tracking_type}</span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Number Of People </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.quantity}</span>
                            </p>
                          </div>
                        </div>

                        {customerDetails?.type !== "Vendor" && (
                          <>
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>Payment method</p>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <img src={images["paypal1.png"]} alt="" />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>Payment </p>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <div className="success_tag">Success</div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="d-flex ">
                          <Button
                            type="primary"
                            icon={<PrinterOutlined />}
                            onClick={handlePrint}
                            className="print_btn_cls  "
                          >
                            Print Pass Details
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightpass_content">
                    <img src={images["pass-detail.png"]} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPassDetail;
