import { createSlice } from "@reduxjs/toolkit";
import { buypassCheckout } from "../Action/buyPassAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  purchaseBuypassData: null,
};

const buyPassSlice = createSlice({
  name: "buypass",
  initialState,
  reducers: {
    setPurchaseDetails(state,{payload}) {
      return {
        ...state,
        loading: false,
        purchaseBuypassData: payload,
      };
    },
  },
  extraReducers:(builder) => {
    builder
    .addCase(buypassCheckout.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(buypassCheckout.fulfilled, (state, { payload }) => {
      state.loading = false;
      // successMsg("Login successful");
    })
    .addCase(buypassCheckout.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      // errorMsg(payload || "Login failed");
    })
  }

  
});

export const { setPurchaseDetails } = buyPassSlice.actions;

export default buyPassSlice;
