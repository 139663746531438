import React from 'react'
import { Outlet } from "react-router-dom";
import Header from './Layout/header/Header';
import Footer from './Layout/footer/Footer';

const MainLayout = () => {

  return (
    <div className="App">
        <Header />
        <Outlet />
        <Footer />
    </div>
  )
}

export default MainLayout