import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/API.js";
import { isloginSuccess } from "../Slice/authSlice.js";

export const buypassCheckout = createAsyncThunk(
  "auth/buypassCheckout",
  async ({ formData, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await API.post("/customer-login", formData, config);
      if (data.status) {
        localStorage.setItem("qctl_customer_id", data.customer_id);
        localStorage.setItem("qctl_password", data.password);
        dispatch(isloginSuccess());
        navigate("/coupon");
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);