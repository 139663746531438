import React from "react";
import "./seatinglocation.scss";
import { NavLink } from "react-router-dom";

const SeatingLocation = ({ data }) => {
  const seatinglocation_data = data?.track_enhancements || [];

  return (
    <div>
      <div className="container">
        <div className="seating_locationSection">
          <div className="seating_locationTitle">
            <h3>{data?.seating_location_heading}</h3>
            <p>{data?.seating_location_sub_heading}</p>
          </div>

          <div className="row">
            {seatinglocation_data.map((v, i) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                  key={i}
                >
                  <div className="seatlocation_outside">
                    <div
                      className="seatinglocation_cards1"
                      style={{ backgroundImage: `url(${v.icon})` }}
                    >
                      <div className="seatingBlog_contain">
                        <p>
                          <NavLink to={"#"}>{v.title}</NavLink>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatingLocation;
