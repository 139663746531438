import React from 'react';
import ReactLoading from "react-loading";

const LoadingBar = () => {
  return (
    <div className=" circulartriangle_loader">
    
    <ReactLoading type={'spokes'} color="#023962" height={'80px'} width={'80px'} />

  </div>
  )
}

export default LoadingBar