import React, { useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { images } from "../../actions/customFn";
 
// import { useTranslation } from "react-i18next";

const MapView = (props) => {
  // const { t } = useTranslation()
  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  let star = 0;

  return (
    <div className="hotel_maplocationmain">
      <GoogleMap
        zoom={props.zoom}
        ref={props.onMapMounted}
        options={{
          disableDefaultUI: true, // This removes all default UI controls
          gestureHandling: "greedy",
        }}
      >
        <div className="usermap-map">
          <Marker
            position={{
              lat: 22.7527421,
              lng: 75.88371599999999,
            }}
            // onClick={() => handleMarkerClick(val)}
          />
          {/* {
            props.showinfowindow == false ? '' :
          
          <InfoWindow
            position={{
              lat: 22.7527421,
              lng: 75.88371599999999,
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className=" hotel-map-location ">
               
                <div className="map_maintoppart" style={{ backgroundImage: `url(${images['map_hotelimg1.png']})` }} >
                   <h6>Casa Larena</h6>
                   <h5><span>€ </span>258 </h5>
                </div>
                <div className="maphotel_belowdetails">
                    <p>Casa / Entire house</p>
                    <div className="info_icons">
                         
                        <p>  <img src={images["graybed.png"]} alt="" /> 3</p>
                        
                        <p> <img src={images["grayperson.png"]} alt="" /> 6</p>
                        
                    </div>
                </div>
              
            </div>
          </InfoWindow>
          } */}
        </div>
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(MapView));;
