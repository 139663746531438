import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createNewPassword } from "../Redux/Action/authAction";
import "../components/Modals/LoginModal/LoginModal.scss";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); 
  const [errors, setErrors] = useState({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(
        createNewPassword({
          password,
          token,
          navigate,
        })
      );
    }
  };

  return (
    <>
      <PageTitleHelmet />
      <div className="login_modalmain reset_password_section">
        <div className="modal_loginsectionmain">
          <h2>Reset Password</h2>
          <p>Welcome to Q.C.T.L.C</p>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label htmlFor="password">Password</label>
                  <div className="password-input-container position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      className="w-100"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="toggle-password"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <div className="error-message">{errors.password}</div>
                  )}
                </div>
              </div>

              <div className="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="password-input-container position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      placeholder="Enter your confirm password"
                      value={confirmPassword}
                      className="w-100"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div
                      className="toggle-password"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>
                  {errors.confirmPassword && (
                    <div className="error-message">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="login_buttonmain">
              <button className="button_darkmain" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
