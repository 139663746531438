import React, { useEffect } from "react";
import { Header, Sidebar } from "../components";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";
import "./VendorDashboard.scss";
import { useGetVendorDashboardMutation } from "../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo } from "../services/customeFn";
import { NavLink } from "react-router-dom";
import { images } from "../actions/customFn";
import Loading from "../services/Loading";
import { useSelector } from "react-redux";

const VendorDashboard = () => {
  const { customer_id, password } = getCustomerInfo();

  const { customerDetails } = useSelector((state) => state.auth);

  const [getVendorDashboadList, { data, isLoading }] =
    useGetVendorDashboardMutation();

  useEffect(() => {
    getVendorDashboadList({ customer_id, password });
  }, []);

  const get_recent_orders = data?.data?.get_recent_orders || [];
  const total_orders_price = data?.data?.total_orders_price || 0;
  const total_orders_count = data?.data?.total_orders_count || 0;
  const total_orders_count_monthly =
    data?.data?.total_orders_count_monthly || 0;

  return (
    <>
      <PageTitleHelmet />
      <div className="coupon-data-main">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>
            <div className="right-bar-data">
              <Loading isLoading={isLoading}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="dashboard-card">
                      <h4>Total Purchases</h4>
                      <p>{total_orders_count}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dashboard-card">
                      <h4>Total Purchases This Month</h4>
                      <p>{total_orders_count_monthly}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dashboard-card">
                      <h4>Total Purchase Price</h4>
                      <p>$ {total_orders_price}</p>
                    </div>
                  </div>
                </div>

                <div className="dashboard-table mt-4">
                  <h4>This Month's Purchases</h4>
                  <div className="">
                    <div className="table-responsive viewpass_tablelist">
                      <table class="table table-striped table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Booking ID</th>
                            <th scope="col">Pass</th>

                            {customerDetails?.type === "Vendor" && (
                              <th scope="col">Customer</th>
                            )}

                            <th scope="col">Price</th>
                            <th scope="col">Startdate</th>
                            <th scope="col">Enddate</th>
                            <th scope="col">Number Of People</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="current_available_table">
                          {get_recent_orders.length == 0 ? (
                            <td
                              colSpan="7"
                              className="loadin_not_found_tr text-center"
                            >
                              <h6> No data found</h6>
                            </td>
                          ) : (
                            get_recent_orders.map((v, i) => (
                              <tr>
                                <td scope="row">{v.order_id}</td>
                                <td scope="row">{v.title}</td>

                                {customerDetails?.type === "Vendor" && (
                                  <td>{v.customer_name}</td>
                                )}

                                <td>${v.price}</td>
                                <td>{v.startdate}</td>
                                <td>{v.enddate}</td>
                                <td>{v.quantity}</td>
                                <td>
                                  {" "}
                                  <div
                                    className={
                                      v.ticketstatus == "Activated"
                                        ? "paid_status"
                                        : v.ticketstatus == "Expired"
                                        ? "cancel_status"
                                        : v.ticketstatus == "Pending"
                                        ? "unpaid_status"
                                        : ""
                                    }
                                  >
                                    {v.ticketstatus}
                                  </div>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <NavLink to={`/viewpass-detail/${v.id}`}>
                                    {" "}
                                    <img
                                      src={images["view_eye.png"]}
                                      alt=""
                                    />{" "}
                                  </NavLink>{" "}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Loading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorDashboard;
