import React, { useState } from "react";
import "./yearlyhistorydetail.scss";

const YearlyHistoryDetail = ({ data }) => {
  const [readmore, setReadmore] = useState([]);

  const yearly_data = data?.qct_history4 || [];

  const openReadmore = (index) => {
    if (readmore.includes(index)) {
      var res = readmore.filter((item) => item != index);
      setReadmore(res);
    } else {
      setReadmore([...readmore, index]);
    }
  };

  return (
    <div className="yearlydetail_historymain">
      <div className="container">
        <div className="history_charlotteBlog">
          <div className="row">
            {yearly_data.map((v, i) => {
              return (
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                  key={i}
                >
                  <div className="yearlyhistory_cards1">
                    <div className="year_txt">
                      <h2>{v.year}</h2>
                    </div>

                    <div className="history_charlottecont">
                      <div className="histmain_img">
                        <img src={v.icon} alt="" />{" "}
                      </div>

                      <h3>{v.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: v.description }}
                        className={readmore.includes(i) ? "show_fullpara" : ""}
                      ></p>
                      <h5 onClick={() => openReadmore(i)}>
                        {readmore.includes(i) ? "Read Less" : "Read More"}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearlyHistoryDetail;
