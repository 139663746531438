import React from "react";
import "./bestlocation.scss";
import { NavLink } from "react-router-dom";

const BestLocation = ({ data }) => {
  const bestlocation_data = data?.view_location || [];

  return (
    <div>
      <div className="container">
        <div className="viewLocation_section">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="bestView_contain">
                <h3>{data?.view_location_heading}</h3>
                <p>{data?.view_location_sub_heading}</p>
              </div>
            </div>
            {bestlocation_data.map((v, i) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                  key={i}
                >
                  <div className="bestlocation_outside">
                    <div
                      className="bestlocation_cards1"
                      style={{ backgroundImage: `url(${v.icon})` }}
                    >
                      <div className="viewLocation_contain">
                        <p>
                          <NavLink to={"#"}>{v.title}</NavLink>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestLocation;
