import React, { useEffect, useState } from "react";
import "./assets/vendor/css/style.scss";
import "./assets/vendor/css/bootstrap.css";
import "./assets/vendor/dist/assets/owl.carousel.css";
import "./assets/vendor/dist/assets/owl.theme.default.min.css";
import Routes from "./Routes/routes";
import { getCustomerDetails, getSettingData } from "./Redux/Action/authAction";
import { useDispatch, useSelector } from "react-redux";
import { LoadingBar } from "./components";


function App() {
  const dispatch = useDispatch();

  const { isAuthenticated, settingloading , settingData } = useSelector(
    (state) => state.auth
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCustomerDetails());
    }
  }, [dispatch, isAuthenticated]);


  // fevicone icon
  useEffect(() => {
    if (settingData && settingData.favicon) {
      const link = document.createElement("link");
      const oldLink = document.querySelector('link[rel="icon"]');

      link.rel = "icon";
      link.href = settingData.favicon;

      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }
  }, [dispatch, settingData]);

  useEffect(() => {
    dispatch(getSettingData());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {settingloading || isLoading ? (
        <LoadingBar />
      ) : (
        <div className="App">
          <Routes />
        </div>
      )}
    </>
  );
}

export default App;
