import React from "react";
import "./nativegallary.scss";

const NativeGallary = ({ data }) => {
  const nativeTreePlanting1 = data?.native_tree_planting1 || [];

  return (
    <div className="container">
      <div className="leaf_grid">
        {nativeTreePlanting1[0] && (
          <div className="side_frstimg">
            <img src={nativeTreePlanting1[0].icon} />
            {nativeTreePlanting1[0].description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: nativeTreePlanting1[0].description,
                }}
              ></p>
            )}
          </div>
        )}

        <div className="right_sidegrid">
          {nativeTreePlanting1[1] && (
            <div className="leaf_imgfirst">
              <img src={nativeTreePlanting1[1].icon} />
              {nativeTreePlanting1[1].description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: nativeTreePlanting1[1].description,
                  }}
                ></p>
              )}
            </div>
          )}

          <div className="ride_sideportion">
            {nativeTreePlanting1[2] && (
              <>
                <img src={nativeTreePlanting1[2].icon} />
                {nativeTreePlanting1[2].description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: nativeTreePlanting1[2].description,
                    }}
                  ></p>
                )}
              </>
            )}
            {nativeTreePlanting1[3] && (
              <>
                <img src={nativeTreePlanting1[3].icon} />
                {nativeTreePlanting1[3].description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: nativeTreePlanting1[3].description,
                    }}
                  ></p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="leaf_gridLayoutpanel">
        <div className="innerLeaf_grid">
          <div className="leaf_gridImg">
            {nativeTreePlanting1[4] && (
              <>
                <img src={nativeTreePlanting1[4].icon} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: nativeTreePlanting1[4].description,
                  }}
                ></p>
              </>
            )}
          </div>

          <div className="right_gridImg">
            {nativeTreePlanting1[5] && (
              <img src={nativeTreePlanting1[5].icon} />
            )}
            {nativeTreePlanting1[6] && (
              <img src={nativeTreePlanting1[6].icon} />
            )}
          </div>
        </div>

        <div className="right_innergrid_Leaf">
          {nativeTreePlanting1[7] && <img src={nativeTreePlanting1[7].icon} />}
        </div>
      </div>
    </div>
  );
};

export default NativeGallary;
