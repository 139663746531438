import React, { useState, useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./contributetrack.scss";
import { errorMsg, successMsg } from "../../../services/customeFn";

const ContributeTrack = () => {
  const [total, setTotal] = useState("25.00");

  // Update total when the selected option changes
  const handleSelectorChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    const amount = selectedOption.getAttribute("data-value");
    const quantity = document.getElementById("quantity").value;
    setTotal((amount * quantity).toFixed(2));
  };

  // Update total when the quantity changes
  const handleQuantityChange = (e) => {
    const quantity = e.target.value;
    const selectedOption = document.getElementById("selector").selectedOptions[0];
    const amount = selectedOption.getAttribute("data-value");
    setTotal((amount * quantity).toFixed(2));
  };

  // Create PayPal order based on total
  const createOrder = useCallback((data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total,  
          },
        },
      ],
    });
  }, [total]); // total as a dependency

  return (
    <div className="donate_section">
      <div className="container">
        <div className="card_in_middlecenter">
          <div className="donate_card">
            <div className="sow-features-feature">
              <div className="sow-icon-image"></div>

              <div className="paypal-form-fields">
                <p className="desc-label">
                  Contribute to the development of the track
                </p>

                <select id="selector" onChange={handleSelectorChange}>
                  <option value="Iron" data-value="25.00">
                    Iron - 25.00 NZD
                  </option>
                  <option value="Bronze" data-value="100.00">
                    Bronze - 100.00 NZD
                  </option>
                  <option value="Silver" data-value="200.00">
                    Silver - 200.00 NZD
                  </option>
                  <option value="Gold" data-value="500.00">
                    Gold - 500.00 NZD
                  </option>
                  <option value="Platinum" data-value="1000.00">
                    Platinum - 1,000.00 NZD
                  </option>
                </select>

                <label className="input-label" htmlFor="quantity">
                  Quantity
                </label>

                <select id="quantity" onChange={handleQuantityChange}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>

                <p>
                  <b>
                    Total: <span id="total">{total} NZD</span>
                  </b>
                </p>
              </div>

              <PayPalScriptProvider
                options={{
                  "client-id": "AAWiGCbEEboE5hukv8ALxE3QI9N2gNI1cq-sJdoqgdS8XqYC3t1_xdsgJ2no0sWAg1RZfc1iWv_Av3nUg",
                  currency: "NZD",
                }}
              >
                <PayPalButtons
                  key={total} // Force re-render when total changes
                  style={{ layout: "vertical" }}
                  createOrder={createOrder}
                  onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                      successMsg(`Transaction completed by ${details.payer.name.given_name}`)
                    });
                  }}
                  onError={(err) => {
                    if (err.message !== "Detected popup close") {
                      errorMsg(
                        err.message || "An error occurred during payment."
                      );
                    }
                  }}
                />
              </PayPalScriptProvider>

              {/* Additional payment methods or PayPal branding can go here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributeTrack;
