import React from "react";
import "./promotionfares.scss";
import { images } from "../../../actions/customFn";

const PromotionFares = ({ data }) => {
  return (
    <div>
      <div className="interislander_section">
        <div className="container">
          <div className="interislander_contain">
            <h3>{data?.receive_heading}</h3>

            <div
              dangerouslySetInnerHTML={{ __html: data?.receive_description }}
            ></div>
          </div>

          <div className="faresPass_img">
            <img src={data?.promo_pass} alt="" />
          </div>

          <div className="Interislander_img">
            <img src={data?.image} alt="" />
          </div>
          <div class="siteorigin-widget-tinymce textwidget">
            <h6>{data?.heading}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionFares;
