import React, { useEffect, useState } from "react";
import { Header, Sidebar } from "../components";
import { images } from "../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useGetCustomerListMutation,
  useDeleteCustomerMutation,
} from "../Redux/RTKQ/pageApiSlice";
import {
  errorMsg,
  getCustomerInfo,
  handleError,
  successMsg,
} from "../services/customeFn";
import { useDispatch } from "react-redux";
import Loading from "../services/Loading";
import { Modal, Button } from "antd";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const Customer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer_id, password, type } = getCustomerInfo();

  const [getCustomerList, { data, error, isLoading }] =
    useGetCustomerListMutation();
  const [deleteCustomer, { isLoading: isDeleting }] =
    useDeleteCustomerMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);

  useEffect(() => {
    getCustomerList({ customer_id, password, type });
  }, [getCustomerList]);

  const customerList = data?.data || [];
  const showDeleteConfirm = (customerId) => {
    setCustomerToDelete(customerId);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (customerToDelete) {
      try {
        await deleteCustomer({
          id: customerToDelete,
          customer_id,
          password,
          type,
        }).unwrap();
        setIsModalVisible(false);
        successMsg("Customer deleted successfully");
        getCustomerList({ customer_id, password, type });
      } catch (error) {
        errorMsg(error);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCustomerToDelete(null);
  };

  return (
    <>
      <PageTitleHelmet />

      <div className="Change-Password-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>
            <div className="right-bar-data">
              <div className="right-side-bg">
                <div className="d-flex justify-content-between mb-3">
                  <h5>Customers</h5>
                  <NavLink to={"/buypass/add"} className="add__btn">
                    Add Customer
                  </NavLink>
                </div>
                <Loading isLoading={isLoading || isDeleting}>
                  <div className="table-responsive viewpass_tablelist">
                    <table className="table table-striped table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Full Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="current_available_table">
                        {isLoading ? (
                          <tr>
                            <td
                              colSpan="4"
                              className="text-center loadin_not_found_tr"
                            >
                              Loading...
                            </td>
                          </tr>
                        ) : customerList.length === 0 ? (
                          <tr>
                            <td
                              colSpan="4"
                              className="loadin_not_found_tr text-center"
                            >
                              <h6> No data found</h6>
                            </td>
                          </tr>
                        ) : (
                          customerList.map((v, i) => (
                            <tr key={i}>
                              <td scope="row">{v.full_name}</td>
                              <td>{v.email}</td>
                              <td>{v.phone_number}</td>
                              <td>
                                <Button
                                  type="link"
                                  onClick={() => showDeleteConfirm(v.id)}
                                >
                                  <img src={images["delete.png"]} alt="" />
                                </Button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </Loading>
                <Modal
                  title="Confirm Deletion"
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  confirmLoading={isDeleting}
                >
                  <p>Are you sure you want to delete this customer?</p>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
