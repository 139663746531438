import React from "react";
import { NavLink } from "react-router-dom";
import { images } from "../../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/fontawesome-free-solid";

import "./footer.scss";
import { useSelector } from "react-redux";
const Footer = () => {
  const { settingData } = useSelector((state) => state.auth);

  const to = ""; // Your 'to' prop value
  return (
    <>
      <div className="footer-main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12">
              <div className="footer_col_1">
                <div className="footer_logo">
                  <NavLink to="/">
                    <img src={settingData?.header_logo} alt="logo" />
                  </NavLink>
                </div>
                <div className="footer-text">
                  <p>{settingData?.footer_description}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12">
              <div className="footer_col_2">
                <ul>
                  <li>
                    <NavLink
                      to={"/"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/contact-us"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/buy-passes"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Buy Passes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/native-tree-planting"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Native Tree Planting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/promotion"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Promotion
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/booking"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Booking
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/qct-history"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      QCT History
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/track-enhancements"}
                      className={({ isActive }) =>
                        to !== "" && isActive ? "active" : ""
                      }
                    >
                      Track Enhancements
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-xs-12">
              <div className="footer_col_3">
                <ul>
                  <li>
                    <NavLink>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                      {settingData?.footer_address}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink>
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      {settingData?.footer_email}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-cpoy-right">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>{settingData?.copyright_text}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
