import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getPageTitle } from "../services/utility";

export const usePageTitle = () => {
  const location = useLocation();
  const pageTitle = useMemo(
    () => getPageTitle(location.pathname),
    [location.pathname]
  );

  return pageTitle;
};

export const PageTitleHelmet = () => {
  const pageTitle = usePageTitle();

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};
