import React, { useState } from "react";
import "./captionarrives.scss";
import { images } from "../../../actions/customFn";
import { Value } from "sass";

const CaptionArrives = ({ data }) => {
  const [readmore, setReadmore] = useState([]);

  const qct_history3 = data?.qct_history3 || [];
  const captionarrive_data = data?.qct_history2 || [];

  const openReadmore = (index) => {
    if (readmore.includes(index)) {
      var res = readmore.filter((item) => item != index);
      setReadmore(res);
    } else {
      setReadmore([...readmore, index]);
    }
  };

  return (
    <div className="captionarrives_mainsection">
      <div className="unified_trackSection">
        <div className="container">
          <div className="row">
            {captionarrive_data.map((v, i) => {
              return (
                <div
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
                  key={i}
                >
                  <div className="tracker_cardpannel">
                    <div className="card_mainimg">
                      <img src={v.icon} alt="" />
                    </div>

                    <div className="unified_trackContent">
                      <h3>{v.year}</h3>
                      <h6>{v.title}</h6>
                      <p
                        dangerouslySetInnerHTML={{ __html: v.description }}
                        className={readmore.includes(i) ? "full_para" : ""}
                      ></p>

                      <h5 onClick={() => openReadmore(i)}>
                        {readmore.includes(i) ? "Read Less" : "Read More"}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {qct_history3.map((value, index) => {
        return (
          <div className="captaindescript_section">
            <div className="container">
              <div className="caption_detailflex">
                <div className="james_img">
                  <img src={value.icon} alt="" />
                </div>

                <div className="james_aboutdescription">
                  <h3>{value.year}</h3>
                  <h6>{value.title}</h6>
                  <p
                    dangerouslySetInnerHTML={{ __html: value.description }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CaptionArrives;
