import React, { useEffect } from "react";
import { images } from "../actions/customFn";
import {
  AboutUs,
  Footer,
  Header,
  HeroSection,
  ImageCard,
  ImageWithText,
  InformationText,
  LoadingBar,
  MiddleBanner,
  OurFaq,
  PurchaseOurPass,
  SupportPlanting,
  TextCard,
  WhatClientSay,

} from "../components";

import { motion } from "framer-motion";
import { useGetPageDataMutation } from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const Home = () => {
  const [getPageById, { data, error, isLoading }] = useGetPageDataMutation();

  useEffect(() => {
    getPageById(1);
    // getPageById(0);
  }, []);

  const pageData = (data && data.data) || {};

  if (isLoading) return <LoadingBar />;
  if (error) return <div>Error loading page data</div>;

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <>
        <PageTitleHelmet />
        <HeroSection data={pageData.banners} />
        <ImageWithText data={pageData} />
        <ImageCard data={pageData} />
        <AboutUs data={pageData} />
        <SupportPlanting data={pageData} />
        <InformationText data={pageData} />
        <TextCard data={pageData} />
        <MiddleBanner data={pageData} />
        <OurFaq
          heading={pageData?.faq_heading}
          faq_list={pageData?.faq || []}
        />
        <PurchaseOurPass
          heading={pageData?.our_app_heading}
          our_app_image={pageData?.our_app_image}
          our_app_playstore_url={pageData?.our_app_playstore_url}
          our_app_applestore_url={pageData?.our_app_applestore_url}
          description={pageData?.our_app_description}
          subheading={pageData?.our_app_sub_heading}
        />
        <WhatClientSay data={pageData} />
      </>
    </motion.div>
  );
};

export default Home;
