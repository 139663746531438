import toast from "react-hot-toast";
 
import { logout } from "../Redux/Slice/authSlice";


// Named export for successMsg
export const successMsg = (msg) => {
  toast.success(msg, {
    position: 'top-right',
    style: {
      boxShadow: '0px 15px 25px rgba(55, 124, 43, 0.2), 0px 5px 10px rgba(55, 124, 43, 0.2)', // Softer, multi-layered shadow
      border: '1px solid transparent', // Border color matching theme
      background: 'linear-gradient(to right, #377C2B, #4CAF50)', // Gradient background for a modern look
      padding: '16px',
      color: '#ffffff', // Text color for contrast
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      borderRadius: "8px",
       zIndex:'99999999'
    },
    iconTheme: {
      primary: '#ffffff', // Icon primary color
      secondary: '#377C2B', // Icon secondary color matching theme
    },
    duration: 4000, // Adjusted duration for a more pleasant display time
  });
};

// Named export for errorMsg
export const errorMsg = (msg) => {
  toast.error(msg, {
    position: 'top-right',
    style: {
      boxShadow: '0px 15px 25px rgba(124, 43, 43, 0.2), 0px 5px 10px rgba(124, 43, 43, 0.2)', // Softer, multi-layered shadow
      border: '1px solid transparent', // Darker red border for error messages
      background: 'linear-gradient(to right, #7C2B2B, #A83939)', // Gradient background for error messages
      padding: '16px',
      color: '#ffffff', // Text color for contrast
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      borderRadius: "8px",
      zIndex:'99999999'
    },
    iconTheme: {
      primary: '#ffffff', // White icon for contrast
      secondary: '#7C2B2B', // Icon secondary color for error matching theme
    },
    duration: 4000, // Adjusted duration for a more pleasant display time
  });
};

 
 

export const handleError = (error,dispatch) => {
  if (!error) return 'An unknown error occurred';
   
  if (error.status === 403 || error.status === 401) {
    // dispatch(logout()); 
    errorMsg('You are not authorized to access this resource.') ;
  } else if (error.status === 404) {
    errorMsg( 'The requested resource was not found.');
  } else if (error.status === 500) {
    errorMsg( 'An internal server error occurred. Please try again later.');
  } 
};


// utils/localStorageUtils.js
export const getCustomerInfo = () => {
  const customer_id = localStorage.getItem("qctl_customer_id");
  const password = localStorage.getItem("qctl_password");
  const type = "Vendor";
  
  return { customer_id, password, type };
};
