import React from "react";
import "./purchasepasslocation.scss";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const PurchasePassLocation = ({ data }) => {
  const picton_value = data?.page_picton_value || [];
  const tracking_value = data?.page_track_value || [];
  const other_placa_value = data?.page_other_value || [];

  return (
    <div className="purchasepass_locationsection">
      <div className="container">
        <div className="purchase_title">
          <h3>{data?.localtion_heading}</h3>
        </div>

        <div className="purchase_row">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="purchaselocation_cards1">
                <div className="purchase_img">
                  <img src={data?.picton_image} alt="" />
                </div>

                <h3>{data?.picton_heading}</h3>

                <div class="siteorigin-widget-tinymce">
                  <ul>
                    {picton_value.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={v.picton_url} target="_blank">
                            {v.picton_title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="purchaselocation_cards1">
                <div className="purchase_img">
                  <img src={data?.track_image} alt="" />
                </div>

                <h3>{data?.track_heading}</h3>

                <div class="siteorigin-widget-tinymce">
                  <ul>
                    {tracking_value.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={v.track_url} target="_blank">
                            {v.track_title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="purchaselocation_cards1">
                <div className="purchase_img">
                  <img src={data?.other_place_image} alt="" />
                </div>

                <h3>{data?.other_place_heading}</h3>

                <div class="siteorigin-widget-tinymce">
                  <ul>
                    {other_placa_value.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={v.other_url} target="_blank">
                            {v.other_title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasePassLocation;
