import { createSlice } from "@reduxjs/toolkit";
import {
  changePassword,
  createNewPassword,
  createPurchasePassbyVendor,
  forgetPassword,
  getCustomerDetails,
  getSettingData,
  login,
  updateCustomerProfile,
} from "../Action/authAction";
import { errorMsg, successMsg } from "../../services/customeFn.js";

var isAuthenticated = localStorage.getItem("qctl_customer_id") ? true : false;

const initialState = {
  loading: false,
  error: null,
  success: false,
  customerDetails: null,
  settingData:null,
  settingloading:null,
  isAuthenticated: isAuthenticated,
  vrfyOtpEmail: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isloginSuccess(state) {
      if (
        localStorage.getItem("qctl_customer_id") != null &&
        localStorage.getItem("qctl_password")
      ) {
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
          error: null,
        };
      }
    },
    logout(state) {
      state.isAuthenticated = false;
      state.customerDetails = null;
      localStorage.clear();
      // successMsg("Logout Successfully");
    },
  },

  extraReducers: (builder) => {
    builder
      //  Login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        successMsg("Login successful");
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Login failed");
      })

      // get user details
      .addCase(getCustomerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomerDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.customerDetails = payload;
      })
      .addCase(getCustomerDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      // forgetPassword
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        localStorage.setItem("qctl_forgetEmail", payload.email);
        successMsg(payload.message);
      })
      .addCase(forgetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      //Create new passowrd
      .addCase(createNewPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        localStorage.removeItem("qctl_forgetEmail");
        successMsg(payload.message);
      })
      .addCase(createNewPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      // update customer
      .addCase(updateCustomerProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomerProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.customerDetails = payload;
        successMsg("Profile updated successfully");
      })
      .addCase(updateCustomerProfile.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      // update customer
      .addCase(createPurchasePassbyVendor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPurchasePassbyVendor.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(createPurchasePassbyVendor.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      // update customer
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        successMsg("Password change successfully");
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      })

      // update customer
      .addCase(getSettingData.pending, (state) => {
        state.settingloading = true;
        state.error = null;
      })
      .addCase(getSettingData.fulfilled, (state, { payload }) => {
        state.settingloading = false;
        state.settingData = payload;
      })
      .addCase(getSettingData.rejected, (state, { payload }) => {
        state.settingloading = false;
        state.error = payload;
        errorMsg(payload || "Something went wrong");
      });
  },
});

export const { logout, isloginSuccess } = authSlice.actions;

export default authSlice;
