import React, { useEffect, useState } from "react";
import {
  Accommodations,
  Footer,
  Header,
  LoadingBar,
  PassBanner,
  WaterTransport,
} from "../components";
import { images } from "../actions/customFn";
import { motion } from "framer-motion";
import { useGetPageDataMutation } from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const Booking = () => {
  const [getPageById, { data, error, isLoading }] = useGetPageDataMutation();

  useEffect(() => {
    getPageById(7);
  }, []);

  const pageData = (data && data.data) || {};

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
       <PageTitleHelmet />
      {isLoading == true ? (
        <LoadingBar />
      ) : (
        <>
          <PassBanner
            heading={pageData?.booking_heading}
            banner_image={pageData?.booking_image}
          />
          <WaterTransport data = {pageData} />
          <Accommodations  data = {pageData} />
        </>
      )}
    </motion.div>
  );
};

export default Booking;
