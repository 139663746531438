import React, { useEffect } from "react";
import "./couponcard.scss";
import { images } from "../../../actions/customFn";
import { useGetCouponListMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo } from "../../../services/customeFn";
import LoadingBar from "../../LoaderLoding/LoadingBar";
const CouponCard = () => {
  const [getCouponList, { data, isLoading }] = useGetCouponListMutation();

  const { customer_id, password } = getCustomerInfo();

  useEffect(() => {
    getCouponList({ customer_id, password });
  }, []);

  const CouponList = data?.data || [];

  return (
    <>
      {isLoading ? (
        <LoadingBar />
      ) : (
        <div className="coupon-card-main">
          <div className="card-bg-data">
            <div className="header-top">
              <h2>Top coupons available</h2>
            </div>

            <div className="coupons-data-grid">
              {CouponList.map(function (value, index) {
                return (
                  <div className="card-one-data" key={index}>
                    <div className="offer-text">
                      <h2>{value?.value}</h2>

                      <div className="top-left"></div>
                      <div className="top-right"></div>
                      <div className="bottom-left"></div>
                      <div className="bottom-right"></div>
                    </div>
                    <div className="coupon-crd-bg">
                      <div className="travel-text">
                        <h2>{value?.title}</h2>
                        <img src={value?.image} alt="" />
                        <div className="bottom-center"></div>
                      </div>
                      <div className="Coupon-Code">
                        <p>Coupon Code</p>
                        <h2>{value?.code}</h2>
                        <div className="text-code">
                          <p>
                          {value?.description}
                          </p>
                        </div>
                        <div className="mid-center"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouponCard;
