import React, { useState } from "react";
import "./LoginModal.scss";
import { images } from "../../../actions/customFn";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import SignUpModal from "./SignUpModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { login } from "../../../Redux/Action/authAction";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../services/Loading";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const LoginModal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const { loginmodal, closeLoginModal, isResetModal, fromBuyPass } = props;
  const [signuppage, setSignupPart] = useState(0);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = {};
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formData.email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    }
    setErrors(errors);

    if (Object.values(errors).length === 0) {
      dispatch(login({ formData, navigate, location, fromBuyPass }));
    }
  };

  return (
    <div>
      <Modal
        className="login_modalmain"
        show={loginmodal}
        onHide={closeLoginModal}
        centered
      >
        <Loading isLoading={loading}>
          <Modal.Body>
            <div className="closemodal_btn">
              <button
                onClick={() => {
                  closeLoginModal();
                  setSignupPart(0);
                }}
              >
                <img src={images["cross-icon.png"]} alt="" />
              </button>
            </div>
            <div className="modal_loginsectionmain">
              {signuppage === 1 ? (
                <>
                  <SignUpModal
                    setSignupPart={setSignupPart}
                    isResetModal={isResetModal}
                  />
                </>
              ) : signuppage === 2 ? (
                <ForgotPasswordModal
                  setSignupPart={setSignupPart}
                  closeLoginModal={closeLoginModal}
                />
              ) : (
                <form onSubmit={handleLogin}>
                  <div className="login_rightcontentpart">
                    <h2>LogIn</h2>

                    <p>Welcome to Q.C.T.L.C</p>

                    <div className="inputall_main">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        value={formData.email}
                        name="email"
                        onChange={handleInputChange}
                      />

                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </div>

                    <div className="inputall_main">
                      <label>Password</label>
                      <div className="password-input-container position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter your password"
                          value={formData.password}
                          onChange={handleInputChange}
                          className="w-100"
                        />
                        <div
                          className="toggle-password"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )}
                        </div>
                      </div>
                      {errors.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </div>

                    <div className="forgotpassword">
                      <button type="button" onClick={() => setSignupPart(2)}>
                        Forgot Password?
                      </button>
                    </div>

                    <div className="login_buttonmain">
                      <button type="submit" className="button_darkmain">
                        {loading ? "Logging in..." : "Login"}
                      </button>
                    </div>

                    <div class="or_section">
                      <div className="horizont_line">
                        <p></p>
                      </div>

                      <p className="signup_link">
                        Don't have an account?
                        <button class="" onClick={() => setSignupPart(1)}>
                          Signup
                        </button>
                      </p>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </Modal.Body>
        </Loading>
      </Modal>
    </div>
  );
};

export default LoginModal;
