import React, { useEffect, useRef, useState } from "react";
import "./passbanner.scss";
import { images } from "../../actions/customFn";

const PassBanner = (props) => {

  const {heading, banner_image} = props;

  return (
    <div>
      <div
        className="purchasePass_banner"
        style={{ backgroundImage: `url(${banner_image})` }}
      >
        <div class="after_iconImg"></div>
        <div class="before_iconImg"></div>

        <div className="container">
          <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
              <div
                class="purchasePass_bannerContain"
                style={{ backgroundImage: `url(${images["map_outline.png"]})` }}
              >
                <h3>{heading}</h3>
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12  p-0">
              <div class="purchasePass_bannerImg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassBanner;
