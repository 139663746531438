import React, { useEffect, useState } from "react";
import {
  useAddSurveyDataMutation,
  useGetSurveyDataMutation,
} from "../Redux/RTKQ/pageApiSlice";
import "./Survey.scss"; // Import custom styles
import { successMsg } from "../services/customeFn";
import Loading from "../services/Loading";

const Survey = () => {
  const [getSurveyData, { isLoading: isDataLoading }] =
    useGetSurveyDataMutation();
  const [addSurveyData, { isLoading: addSurverLoading }] =
    useAddSurveyDataMutation();
  const [surveyData, setSurveyData] = useState([]);
  const [totalSurvey, setTotalSurvey] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSurveyData(0); // Fetch the first set of questions
  }, []);

  const fetchSurveyData = async (step) => {
    const offset = step * 4; // Calculate the offset based on the step
    const result = await getSurveyData({ limit: 4, offset }).unwrap();
    if (result.status) {
      setSurveyData(result.data);
      setTotalSurvey(result.total_survey);
    }
  };

  const handleInputChange = (id, value) => {
    setResponses((prev) => ({ ...prev, [id]: value }));
    setValidationErrors((prev) => ({ ...prev, [id]: false }));
  };

  const validateCurrentStep = () => {
    const errors = {};
    surveyData.forEach((question) => {
      if (
        !responses[question.id] ||
        (Array.isArray(responses[question.id]) &&
          responses[question.id].length === 0)
      ) {
        errors[question.id] = true;
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNextStep = async () => {
    if (validateCurrentStep()) {
      const nextStep = currentStep + 1;
      const nextOffset = nextStep * 4;

      if (nextOffset < totalSurvey) {
        setCurrentStep(nextStep);
        await fetchSurveyData(nextStep);
      } else {
        // If no more questions, submit the survey
        const formData = responses;
        const result = await addSurveyData({ formData });
        if (result?.data?.status) {
          successMsg(result?.data?.message);
          setResponses({});
          setCurrentStep(0);
          fetchSurveyData(0);
        }
      }
    }
  };

  const handlePreviousStep = async () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      await fetchSurveyData(prevStep);
    }
  };

  return (
    <div className="survey-form-container">
      <div className="survey-form">
        <Loading isLoading={isDataLoading || addSurverLoading}>
          <h5 className="mb-4">Survey Form</h5>
          {surveyData.length === 0 ? (
            <>
            <h5 className="min_height_20">Loading....</h5>
            
            </>
          ) : (
            <>
              {surveyData.map((question) => (
                <div key={question.id} className="question">
                  <label className="question-title">{question.title}</label>
                  {question.input_type === "Textbox" && (
                    <input
                      type="text"
                      className={`text-input ${
                        validationErrors[question.id] ? "input-error" : ""
                      }`}
                      onChange={(e) =>
                        handleInputChange(question.id, e.target.value)
                      }
                      value={responses[question.id] || ""}
                      placeholder="Type your answer here"
                    />
                  )}
                  {question.input_type === "RadioButtons" &&
                    question.option.map((option, index) => (
                      <div key={index} className="radio-option">
                        <input
                          type="radio"
                          name={`question-${question.id}`}
                          value={option}
                          checked={responses[question.id] === option}
                          onChange={(e) =>
                            handleInputChange(question.id, e.target.value)
                          }
                        />
                        <label>{option}</label>
                      </div>
                    ))}
                  {question.input_type === "Checkboxes" &&
                    question.option.map((option, index) => (
                      <div key={index} className="checkbox-option">
                        <input
                          type="checkbox"
                          name={`question-${question.id}`}
                          value={option}
                          checked={responses[question.id]?.includes(option)}
                          onChange={(e) => {
                            const updatedOptions = responses[question.id] || [];
                            if (e.target.checked) {
                              updatedOptions.push(option);
                            } else {
                              const index = updatedOptions.indexOf(option);
                              if (index > -1) {
                                updatedOptions.splice(index, 1);
                              }
                            }
                            handleInputChange(question.id, updatedOptions);
                          }}
                        />
                        <label>{option}</label>
                      </div>
                    ))}
                  {validationErrors[question.id] && (
                    <div className="error-message">This field is required.</div>
                  )}
                </div>
              ))}

              <div className="navigation-buttons">
                {currentStep > 0 && (
                  <button
                    className="nav-button previous-button"
                    onClick={handlePreviousStep}
                  >
                    Previous
                  </button>
                )}
                <button
                  className="nav-button next-button"
                  onClick={handleNextStep}
                >
                  {currentStep * 4 + surveyData.length >= totalSurvey
                    ? "Submit"
                    : "Next"}
                </button>
              </div>
            </>
          )}
        </Loading>
      </div>
    </div>
  );
};

export default Survey;
