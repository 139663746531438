import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { images } from "../actions/customFn";
import { Header, LoadingBar } from "../components";
import "./pages.scss";
import { useCustomerVerifyMutation } from "../Redux/RTKQ/pageApiSlice";
import Loading from "../services/Loading";
import { errorMsg, successMsg } from "../services/customeFn";
const VerifyThankyoupage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [CustomerVerify, { data, isLoading }] = useCustomerVerifyMutation();
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    const verifyCustomer = async () => {
      if (id) {
        const response = await CustomerVerify({
          verification_string: id,
        }).unwrap();

        if (response.status) {
          navigate("/email-verification");
        } else {
          navigate("/");
        }
      }
    };

    verifyCustomer();
  }, [id, CustomerVerify, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <div className="thankyou-page-main">
        {isLoading || pageLoading ? (
          <LoadingBar />
        ) : !id ? (
          <div
            className="bg-image"
            style={{
              backgroundImage: `url(${images["image-bg.png"]})`,
            }}
          >
            <div className="container">
              <div className="inner-data-thank-you">
                <div className="flex-data-row">
                  <div className="data-main-inner wd-50-info">
                    <h2>Thank You!</h2>
                    <p>
                      Your account has been successfully verified. Welcome
                      aboard!
                    </p>

                    <div className="btn-both-side">
                      <p>What you want to do next.?</p>
                      <div className="btn-grid-inner">
                        <NavLink to="/" className="btn-transparent">
                          {" "}
                          Back to Home
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="data-main-inner wd-50-info">
                    <img src={images["thank_you.png"]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default VerifyThankyoupage;
