import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { images } from "../actions/customFn";
import { Header } from "../components";
import "./pages.scss";
const Thankyoupage = () => {
  const { id } = useParams();
  return (
    <>
      <div className="thankyou-page-main">
        <div className="header-pages">
          <Header />
        </div>
        <div
          className="bg-image"
          style={{
            backgroundImage: `url(${images["image-bg.png"]})`,
          }}
        >
          <div className="container">
            <div className="inner-data-thank-you">
              <div className="flex-data-row">
                <div className="data-main-inner wd-50-info">
                  <h2>Thank You!</h2>
                  <p>You have just purchased a pass</p>

                  <div className="btn-both-side">
                    <p>What you want to do next.?</p>
                    <div className="btn-grid-inner">
                      <NavLink to="/" className="btn-transparent">
                        {" "}
                        Back to Home
                      </NavLink>
                      <NavLink
                        to={`/viewpass-detail/${id}`}
                        className="btn-common"
                      >
                        View pass detail
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="data-main-inner wd-50-info">
                  <img src={images["thank.png"]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyoupage;
