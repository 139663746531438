// CheckOut.jsx
import React, { useEffect, useState } from "react";
import { CheckOutForm, Header, LoadingBar, OrderSummary } from "../components";
import { motion } from "framer-motion";
import {
  useCheckoutBuypassMutation,
  useGetBuyPassDetailsMutation,
} from "../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo, successMsg } from "../services/customeFn";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CheckOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { customerDetails, loading } = useSelector((state) => state.auth);
  const { customer_id, password, type } = getCustomerInfo();
  const { number_of_people, date_of_visit , pass_id } = location.state || {};

  
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [purchaseBuypassData, setPurchaseBuypassData] = useState(null);
  const [getBuyPassDetails, { data: buyPassData, isLoading: buyPassLoading }] =
    useGetBuyPassDetailsMutation();
  const [checkoutBuypass] = useCheckoutBuypassMutation();

  const [formdata, setFormdata] = useState({
    customer_id,
    password,
    quantity: null,
    type,
    payment_method: "paypal",
    tracking_type: "Cycling",
    trackingpass_id: null,
    start_date: null,
    end_date: null,
    payResponse: null,
    selected_customer_id: "self",
  });

  useEffect(() => {

    if(customerDetails?.type == "Vendor"){
      navigate("/buy-passes")
    }

    if (number_of_people && pass_id && date_of_visit) {

      setPurchaseBuypassData({
        start_date: date_of_visit,
        quantity: number_of_people,
      });

      getBuyPassDetails({
        customer_id,
        password,
        type,
        buy_pass_id: pass_id,
        start_date: date_of_visit,
      });

      setFormdata((prev) => ({
        ...prev,
        quantity: number_of_people,
        trackingpass_id: pass_id,
        start_date: date_of_visit,
      }));
    }else{
      navigate("/buy-passes")
    }
  }, [getBuyPassDetails, customer_id, password, type]);


  const validateFormData = () => {

    const newErrors = {};
    
    if (!formdata.payment_method) {
      newErrors.payment = "Please select a payment method";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      try {
        const response = await checkoutBuypass(formdata).unwrap();
        if (response.status) {
          successMsg(response.message);
          navigate(`/thankyou-page/${response.order_id}`);
        }
      } catch (error) {
        console.error("Checkout failed:", error);
      }
    }
  };

  const handleSuccessfulPayment = async (details) => {
    try {
      setFormdata((prevFormdata) => {
        const updatedFormdata = {
          ...prevFormdata,
          payResponse: details,
        };

        // Continue with the API call using the updated state
        checkoutBuypass(updatedFormdata)
          .unwrap()
          .then((response) => {
            if (response.status) {
              successMsg(response.message);
              navigate(`/thankyou-page/${response.order_id}`);
            }
          })
          .catch((error) => {
            console.error("Checkout failed:", error);
          });

        return updatedFormdata;
      });
    } catch (error) {
      console.error("Checkout failed:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      {isLoading || buyPassLoading ? (
        <LoadingBar />
      ) : (
        <>
          <div className="header_outershadow">
            <Header />
          </div>
          <form onSubmit={handleCheckout}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-7 col-md-12">
                  <CheckOutForm
                    formdata={formdata}
                    setFormdata={setFormdata}
                    errors={errors}
                    validateFormData={validateFormData}
                  />
                </div>
                <div className="col-xl-4 col-lg-5 col-md-12">
                  <OrderSummary
                    buyPassDetails={buyPassData?.data || {}}
                    purchaseBuypassData={purchaseBuypassData}
                    paymentMethod={formdata.payment_method}
                    errors={errors}
                    formdata={formdata}
                    validateFormData={validateFormData}
                    onSuccessfulPayment={handleSuccessfulPayment}
                  />
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </motion.div>
  );
};

export default CheckOut;
