import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "../Slice/authSlice";
import { errorMsg } from "../../services/customeFn";

const baseQueryWithErrorHandling = fetchBaseQuery({
  baseUrl: "https://qctlcapp.infosparkles.com/admin/api",
});

const baseQuery = async (args, api, extraOptions) => {
  const result = await baseQueryWithErrorHandling(args, api, extraOptions);

  if (result.error) {
    const { status, data } = result.error;

    // Default error message
    let errorMessage = data?.message || "An error occurred. Please try again.";

    // Handle specific status codes
    switch (status) {
      case 400:
        errorMessage = "Bad request. Please check your input.";
        break;
      case 401:
        errorMessage = "Unauthorized access. Please log in again.";
        // Log the user out on 401 Unauthorized
        api.dispatch(logout());
        break;
      case 404:
        errorMessage = "Resource not found.";
        break;
      case 500:
        errorMessage = "Internal server error. Please try again later.";
        break;
      default:
        // Keep the default error message for other status codes
        break;
    }

    // Display the error message using errorMsg function
    errorMsg(errorMessage);
  } else if (result.data && result.data.status === false) {
    // Handle validation errors or other cases where the status is false
    const errorMessage = result.data.message || "Validation failed. Please check your input.";
    errorMsg(errorMessage);
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery,
  endpoints: () => ({}),
});

export default apiSlice;
