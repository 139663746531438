import React, { useState } from "react";
import { useSignupMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { errorMsg, successMsg } from "../../../services/customeFn";
import Loading from "../../../services/Loading";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"; // Import icons from Ant Design

const SignUpModal = (props) => {
  const { setSignupPart } = props;

  const [customerSignup, { data, isLoading }] = useSignupMutation();

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.full_name) {
      errors.full_name = "Full name is required";
    }

    const phoneRegex = /^\d{10}$/;
    if (!formData.phone_number) {
      errors.phone_number = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone_number)) {
      errors.phone_number = "Invalid Phone Number";
    }

    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (!isTermsAccepted) {
      errors.isTermsAccepted = "You must accept the terms and conditions";
    }

    setErrors(errors);

    if (Object.values(errors).length === 0) {
      try {
        const response = await customerSignup(formData).unwrap();

        if (response.status) {
          successMsg("Signup successfully");
          setSignupPart(false);
        }
      } catch (error) {
        console.error("Signup error:", error);
        errorMsg("An error occurred during signup. Please try again.");
      }
    }
  };

  return (
    <>
      <Loading isLoading={isLoading}>
        <div className="login_rightcontentpart">
          <h2>SignUp</h2>

          <p>Welcome to Q.C.T.L.C</p>

          <form onSubmit={handleSignup}>
            <div className="row">
              {/* Full Name */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label>Full name</label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    name="full_name"
                    onChange={handleInputChange}
                    value={formData.full_name}
                  />
                  {errors.full_name && (
                    <div className="error-message">{errors.full_name}</div>
                  )}
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label>Phone number</label>
                  <input
                    type="text"
                    placeholder="Enter your Mobile number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                  {errors.phone_number && (
                    <div className="error-message">{errors.phone_number}</div>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label>Email address</label>
                  <input
                    type="text"
                    placeholder="Enter your Email address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
              </div>

              {/* Password */}
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="inputall_main">
                  <label>Password</label>
                  <div className="password-input-container position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your Password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="w-100 position-relative"
                    />
                    <div
                      className="password-toggle-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <div className="error-message">{errors.password}</div>
                  )}
                </div>
              </div>

              {/* Confirm Password */}
              <div className="col-12">
                <div className="inputall_main">
                  <label>Confirm password</label>
                  <div className="password-input-container position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Enter your Confirm password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      className="w-100 "
                    />
                    <div
                      className="password-toggle-icon"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </div>
                  </div>
                  {errors.confirmPassword && (
                    <div className="error-message">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Terms and Conditions Checkbox */}
            <div className="term_checkbox">
              <input
                type="checkbox"
                id="checkterm"
                checked={isTermsAccepted}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="checkterm">
                I accept all terms and conditions
              </label>
            </div>
            {errors.isTermsAccepted && (
              <div className="error-message">{errors.isTermsAccepted}</div>
            )}

            <div className="login_buttonmain">
              <button type="submit" className="button_darkmain">
                Sign Up
              </button>
            </div>
          </form>

          <div className="or_section">
            <div className="horizont_line">
              <p></p>
            </div>

            <p className="signup_link">
              Already have an account?
              <button className="" onClick={() => setSignupPart(0)}>
                Login
              </button>
            </p>
          </div>
        </div>
      </Loading>
    </>
  );
};

export default SignUpModal;
