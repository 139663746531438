import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

export default function Loading({ isLoading, children }) {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#377C2B" }} spin />
  );

  return (
    <>
      {children ? (
        <Spin indicator={antIcon} spinning={isLoading}>
          {children}
        </Spin>
      ) : (
        <div
          className="centerAbsolute"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" indicator={antIcon} />
        </div>
      )}
    </>
  );
}
