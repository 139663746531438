import React from "react";
import { images } from "../../../actions/customFn";
import "./imagewithtext.scss";
import { NavLink } from "react-router-dom";
const ImageWithText = ({data}) => {
  console.log("data",data);
  
  return (
    <div className="image-text-main-section">
      <div className="container">
        <div className="both-side-grid wd-info-grid">
          <div className="image-side-box wd-info-50">
            <div className="image-side-rigth">
              <img src={data?.stretching_image} alt="" />
            </div>
          </div>
          <div className="text-side-box wd-info-50">
            <div className="title-inner">
              <h3 class="widget-title">
                {data?.stretching_heading}
              </h3>
            </div>

            <div className="text-all-info">
           
              <p  dangerouslySetInnerHTML={{ __html: data && data.stretching_description }}>
              </p>
            </div>
            <div className="btn-inner">
              <NavLink to={'/qct-history'} className="btn-common">Read More</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithText;
