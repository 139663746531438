import React from "react";
import "./nativeplanting.scss";

const NativePlanting = ({ data }) => {
  const supportNativeTreePlanting = data?.support_native_tree_planting || [];

  return (
    <div className="container">
      <div className="nativePlant_section">
        <div className="tree_GridImg">
          {supportNativeTreePlanting[0] && (
            <div className="left_firstimage">
              <img
                src={supportNativeTreePlanting[0].icon}
                alt={supportNativeTreePlanting[0].alt || "Native Tree Image 1"}
              />
            </div>
          )}
          <div className="right_treeimage">
            {supportNativeTreePlanting[1] && (
              <img
                src={supportNativeTreePlanting[1].icon}
                alt={supportNativeTreePlanting[1].alt || "Native Tree Image 2"}
              />
            )}
            {supportNativeTreePlanting[2] && (
              <img
                src={supportNativeTreePlanting[2].icon}
                alt={supportNativeTreePlanting[2].alt || "Native Tree Image 3"}
              />
            )}
          </div>
        </div>

        <div className="nativePlant_contain">
          <h3>{data?.support_heading}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: data?.support_description }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default NativePlanting;
