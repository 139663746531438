import { ContactUs } from "../../pages";
import apiSlice from "./apiSlice";

export const pageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPageData: builder.mutation({
      query: (id) => ({
        url: "/pagedetail",
        method: "POST",
        body: { page_id: id },
      }),
    }),

    signup: builder.mutation({
      query: (param) => ({
        url: "/customer-signup",
        method: "POST",
        body: param,
      }),
    }),
    contactUs: builder.mutation({
      query: (param) => ({
        url: "/contact-us",
        method: "POST",
        body: param,
      }),
    }),

    getCouponList: builder.mutation({
      query: (param) => ({
        url: "/customer-coupons-list",
        method: "POST",
        body: param,
      }),
    }),

    customerVerify: builder.mutation({
      query: (param) => ({
        url: "/email-verification",
        method: "POST",
        body: param,
      }),
    }),

    getCustomerList: builder.mutation({
      query: (param) => ({
        url: "/get-vendor-customers",
        method: "POST",
        body: param,
      }),
      providesTags: ["CustomerList"],
    }),

    getBuyPassDetails: builder.mutation({
      query: (param) => ({
        url: "/get_buy_pass_details",
        method: "POST",
        body: param,
      }),
      providesTags: ["CustomerList"],
    }),

    deleteCustomer: builder.mutation({
      query: (param) => ({
        url: "/delete-vendor-customer",
        method: "POST",
        body: param,
      }),
      invalidatesTags: ["CustomerList"],
    }),

    checkoutBuypass: builder.mutation({
      query: (param) => ({
        url: "/customer-purchase-pass",
        method: "POST",
        body: param,
      }),
    }),

    myPurchasePasslist: builder.mutation({
      query: (param) => ({
        url: "/customer-purchased-pass-list",
        method: "POST",
        body: param,
      }),
    }),

    getPurchasedPassDetails: builder.mutation({
      query: (param) => ({
        url: "/customer-purchased-pass-details",
        method: "POST",
        body: param,
      }),
    }),

    getSurveyData: builder.mutation({
      query: (param) => ({
        url: "/get-survey",
        method: "POST",
        body: param,
      }),
    }),

    addSurveyData: builder.mutation({
      query: (param) => ({
        url: "/add-survey",
        method: "POST",
        body: param,
      }),
    }),

    getPassList: builder.mutation({
      query: (param) => ({
        url: "/buypasslist",
        method: "POST",
        body: param,
      }),
    }),


    getVendorDashboard: builder.mutation({
      query: (param) => ({
        url: "/dashboard",
        method: "POST",
        body: param,
      }),
    }),


    

    
  }),
  overrideExisting: false,
});

export const {
  useGetPageDataMutation,
  useGetCustomerListMutation,
  useDeleteCustomerMutation,
  useGetBuyPassDetailsMutation,
  useCheckoutBuypassMutation,
  useMyPurchasePasslistMutation,
  useGetPurchasedPassDetailsMutation,
  useSignupMutation,
  useCustomerVerifyMutation,
  useGetCouponListMutation,
  useContactUsMutation,
  useGetSurveyDataMutation,
  useAddSurveyDataMutation,
  useGetPassListMutation,
  useGetVendorDashboardMutation,
} = pageApiSlice;
