import React from "react";
import "./middlebanner.scss";
import { images } from "../../../actions/customFn";
const MiddleBanner = ({ data }) => {
  return (
    <div className="middle-banner-main-section">
      <div
        className="banner-bg-image"
        style={{
          backgroundImage: `url(${data?.whenever_image})`,
        }}
      >
        <div className="text-banner">
          <h2>{data?.whenever_heading}</h2>
          <p>{data?.whenever_sub_heading}</p>
        </div>
      </div>
    </div>
  );
};

export default MiddleBanner;
