import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './RTKQ/apiSlice';
import authSlice from './Slice/authSlice';
import buyPassSlice from './Slice/buyPassSlice';


const store = configureStore({
  reducer: {
    // Add the API slice reducer
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth:authSlice.reducer,
    buyPass:buyPassSlice.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
