import React from "react";
import "./trackviewpoint.scss";
import { images } from "../../../actions/customFn";

const TrackViewPoint = ({data}) => {
  const viewpont_data = data?.nature_enhancements || [];

  return (
    <div className="trackview_pointmain">
      <div className="container">
        <div className="row">
          {viewpont_data.map((value, index) => {
            return (
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="viewpoint_cards1">
                  <div className="left_mainimg">
                    <img src={value.icon} alt="" />
                  </div>
                  <div className="viepoint_content">
                    <h3>{value.title}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TrackViewPoint;
