import React, { useEffect, useState } from "react";
import {
  ContactusForm,
  Footer,
  Header,
  LoadingBar,
  PassBanner,
} from "../components";
import { images } from "../actions/customFn";
import { motion } from "framer-motion";
import { useGetPageDataMutation } from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const ContactUs = () => {
  const [getPageById, { data, error, isLoading }] = useGetPageDataMutation();

  useEffect(() => {
    getPageById(2);
  }, []);

  const pageData = (data && data.data) || {};

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <PageTitleHelmet />
      {isLoading == true ? (
        <LoadingBar />
      ) : (
        <>
          <PassBanner
            heading={pageData?.feedback_heading}
            banner_image={pageData?.feedback_image1}
          />
          <ContactusForm data={pageData} />
        </>
      )}
    </motion.div>
  );
};

export default ContactUs;
