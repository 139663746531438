import React, { useEffect, useState } from "react";
import {
  CaptionArrives,
  Footer,
  Header,
  LoadingBar,
  MaoriDetail,
  PassBanner,
  PurchaseOurPass,
  YearlyHistoryDetail,
} from "../components";
import { motion } from "framer-motion";
import { useGetPageDataMutation } from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const QCTHistory = () => {
  const [getPageById, { data, error, isLoading }] = useGetPageDataMutation();

  useEffect(() => {
    getPageById(5);
  }, []);

  const pageData = (data && data.data) || {};

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
        <PageTitleHelmet />
      {isLoading == true ? (
        <LoadingBar />
      ) : (
        <>

          <PassBanner
            heading={pageData?.qct_history_heading}
            banner_image={pageData?.qct_history_image}
          />

          <MaoriDetail data={pageData} />
          <CaptionArrives data={pageData} />
          <YearlyHistoryDetail data={pageData} />
          <div className="qcthistory_purchasepass">
            <PurchaseOurPass
              heading={pageData?.our_app_heading}
              subheading={pageData?.our_app_sub_heading}
              description={pageData?.our_app_description}
              our_app_image={pageData?.our_app_image}
              our_app_playstore_url={pageData?.our_app_playstore_url}
              our_app_applestore_url={pageData?.our_app_applestore_url}
            />
          </div>

        </>
      )}
    </motion.div>
  );
};

export default QCTHistory;
