import React from "react";
import "./maoridetail.scss";
import { images } from "../../../actions/customFn";

const MaoriDetail = ({ data }) => {
  const qct_history1 = data?.qct_history1 || [];
  return (
    <div className="maoridetail_mainsection">
      <div className="container">
        <div className="row">
          {qct_history1.map((value, index) => {
            return (
              <>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="maori_img">
                    <img src={value.icon} alt="" />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="maoriArrive_contain">
                    <h3>{value.year}</h3>
                    <h6>{value.title}</h6>
                    <p
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    ></p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MaoriDetail;
