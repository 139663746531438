import React from "react";
import "./whatclientsay.scss";

import OwlCarousel from "react-owl-carousel";
import { images } from "../../../actions/customFn";

const experience = {
  loop: true,
  margin: 0,
  nav: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 800,
  navText: [
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="16" fill="white"/><g opacity="0.9"><path d="M10 16L15 21L15.705 20.295L11.915 16.5H22V15.5H11.915L15.705 11.705L15 11L10 16Z" fill="black"/></g></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="white"/><g opacity="0.9"><path d="M22 16L17 21L16.295 20.295L20.085 16.5H10V15.5H20.085L16.295 11.705L17 11L22 16Z" fill="black"/></g></svg>',
  ],
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  },
};

const WhatClientSay = ({ data }) => {
  const clientsData = data?.clients || [];

  return (
    <div className="whatclient_saysectionmain">
      <div className="container">
        <h2>{data?.client_heading}</h2>

        <div className="clientsay_sectionflex">
          <div className="section_bgleftimg">
            <img
              className="left_imagesection"
              src={data?.client_image}
              alt=""
            />
          </div>

          <div className="clientsay_carosoul">
            <OwlCarousel
              {...experience}
              className=""
              //   nav
              margin={30}
            >
              {clientsData.map((value, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="clientay_cards1">
                      <div className="quote_img">
                        {" "}
                        <img src={images["quotecoma-left.png"]} alt="" />{" "}
                      </div>
                      <p
                        class="slider_txt"
                        dangerouslySetInnerHTML={{ __html: value?.description }}
                      ></p>
                      <h6>{value?.title}</h6>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatClientSay;
