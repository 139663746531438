import React from "react";
import "./aboutus.scss";
import { images } from "../../../actions/customFn";
const AboutUs = ({data}) => {
  return (
    <div className="about-us-main-section">
      <div className="container">
        <div className="both-side-grid wd-info-grid">
          <div className="image-side-box wd-info-50">
            <div className="image-side-rigth">
              <img src={data?.about_us_image} alt="" />
            </div>
          </div>
          <div className="text-side-box wd-info-50">
            <div className="title-inner">
              <h3 class="widget-title">{data?.about_us_heading}</h3>
            </div>

            <div className="text-all-info">
              <p dangerouslySetInnerHTML={{ __html : data?.about_us_description}}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
