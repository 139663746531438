import React from 'react';
import { Link } from 'react-router-dom';
import { images } from '../actions/customFn';

const PageNotFound = () => {
  return (
    <section className="page_404">
      <div className="page_not_found_container">
        <div className="four_zero_four_bg">
          <img src={images['page_not_found.png']} alt="404 Not Found" />
        </div>
        <div className="contant_box_404">
          <h3 className="not_found_title_404">Looks like you're lost</h3>
          <p>The page you are looking for is not available!</p>
          <Link to="/" className="link_404">Go to Home</Link>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
