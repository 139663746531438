import React from "react";

const PrintPass = ({ BuyPassDetails}) => {
  return (
    <>
      <style>
        {`
                          @media print {
                            .hide-from-print {
                              display: none;
                            }
                            .print-only {
                              display: block;
                            }
                          }
                          @media screen {
                            .print-only {
                              display: none;
                            }
                          }
                        `}
      </style>
      <div
        role="article"
        aria-roledescription="email"
        aria-label="email name"
        lang="en"
        style={{
          fontSize: "14px",
          width: "800px",
          margin: "0 auto",
          boxShadow: "0px 30px 40px #000c251a",
          background: "#FAFDFF",
        }}
      >
        <table
          role="presentation"
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
        >
          <tr style={{ width: "100%" }}>
            <td style={{ padding: "25px 15px" }} colSpan="3">
              <img
                src="https://qctlc.com/wp-content/uploads/2023/02/logo-1.png"
                alt="Logo"
                style={{ width: "160px" }}
              />
            </td>
          </tr>
          <tr>
            <td valign="top" colSpan="3">
              <div
                className="over-mob"
                style={{
                  maxHeight: "300px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <img
                  className="reset"
                  src="https://qctlc.com/wp-content/uploads/2023/02/slide1.png"
                  width="800"
                  height="400"
                  border="0"
                  alt="Slide Image"
                  style={{ verticalAlign: "middle" }}
                />
              </div>
              <table
                role="presentation"
                className="faux-absolute reset"
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                width="800"
                style={{ position: "relative", opacity: 0.999 }}
              >
                <tr>
                  <td valign="top">
                    <table
                      role="presentation"
                      className="hero-textbox"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="92%"
                      bgcolor="#FFFFFE"
                      align="center"
                    >
                      <tr>
                        <td valign="top" style={{ padding: "15px" }}>
                          <table style={{ width: "100%" }}>
                            <tr>
                              <td
                                style={{
                                  width: "65%",
                                  padding: "15px 15px 25px 25px",
                                  backgroundImage:
                                    "url('https://qctlc.com/wp-content/uploads/2023/02/left-green.png')",
                                  backgroundSize: "100% 100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              >
                                <table>
                                  <tr>
                                    <td colSpan="2">
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "28px",
                                          color: "#fff",
                                          lineHeight: "42px",
                                        }}
                                      >
                                        {BuyPassDetails?.title}
                                      </h1>
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                          marginBottom: "25px",
                                        }}
                                      >
                                        Good for access to all Q.C.T.L.C land of
                                        the Queen Charlotte Track
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        Name
                                      </p>
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "24px",
                                          color: "#fff",
                                          lineHeight: "30px",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {BuyPassDetails?.customer_name} for {BuyPassDetails?.quantity} People
                                      </h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        Date Of visit
                                      </p>
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "24px",
                                          color: "#fff",
                                          lineHeight: "30px",
                                        }}
                                      >
                                        {BuyPassDetails?.startdate}
                                      </h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          height: "25px",
                                        }}
                                      ></div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          color: "#FFFFFF",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Please Display this pass so that it is
                                        clearly visible
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          color: "#FFFFFF",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {BuyPassDetails?.description}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div
                                        style={{
                                          background: "#fff",
                                          padding: "10px",
                                          width: "140px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <img
                                          src="https://qctlc.com/wp-content/uploads/2023/02/logo-1.png"
                                          style={{
                                            width: "100%",
                                          }}
                                          alt="Logo"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          marginLeft: "auto",
                                          textAlign: "right",
                                          maxWidth: "70%",
                                        }}
                                      >
                                        <img
                                          src="https://qctlc.com/wp-content/themes/qctlc/dist/images/email-template-images/right-two-img.png"
                                          style={{
                                            width: "80%",
                                          }}
                                          alt="Two Images"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  padding: "15px",
                                  backgroundImage:
                                    "url('https://qctlc.com/wp-content/uploads/2023/02/right-green.png')",
                                  backgroundSize: "100% 100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0 auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    src="https://qctlc.com/wp-content/uploads/2023/02/code-img.png"
                                    style={{ width: "92%" }}
                                    alt="Code"
                                  />
                                  <img
                                    src="https://qctlc.com/wp-content/uploads/2023/02/right-logo.png"
                                    style={{ width: "96%" }}
                                    alt="Right Logo"
                                  />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      role="presentation"
                      className="hero-textbox"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="92%"
                      bgcolor="#FFFFFE"
                      align="center"
                    >
                      <tr>
                        <td valign="top" style={{ padding: "15px 15px 0" }}>
                          <h2
                            style={{
                              margin: "0px",
                              fontSize: "26px",
                            }}
                          >
                            Receive 10% off all InterIslander Fares
                          </h2>
                          <p style={{ fontSize: "14px" }}>
                            Receive 10% off all InterIslander fares booked
                            through{" "}
                            <a
                              href="https://www.interislander.co.nz"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              www.interislander.co.nz
                            </a>
                            . Use the promo code QCT2 (on the payment page) and
                            present your pass at check-in.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" style={{ padding: "0 15px 15px" }}>
                          <table style={{ fontSize: "14px" }}>
                            <tr>
                              <td>
                                Terms and conditions apply. See{" "}
                                <a
                                  href="https://www.interislander.co.nz"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  www.interislander.co.nz
                                </a>
                                .
                              </td>
                            </tr>
                            <tr>
                              <td>
                                The survey - Please complete the following
                                survey when you complete the track.{" "}
                                <a
                                  href="https://www.getsmartglobal.com/queencharlottetrack"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  http://www.getsmartglobal.com/queencharlottetrack
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <div style={{ height: "40px" }}></div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
            style={{
              background: "#fff",
              color: "#000",
              textAlign: "left",
              position: "relative",
              top: "0px",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "21px",
            }}
          ></tr>
          <tr
            style={{
              background: "#fff",
              borderTop: "2px solid #02396296",
            }}
          >
            <td colSpan="3">
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ padding: "10px 0px 15px 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://qctlc.com/wp-content/themes/qctlc/dist/images/email-template-images/address-icon.png"
                        style={{
                          marginTop: "-5px",
                          marginRight: "5px",
                          width: "50px",
                        }}
                        alt="Address Icon"
                      />
                      17 Painters. Road RD1 Christchurch 7671
                    </div>
                  </td>
                  <td style={{ padding: "10px 0px 15px 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://qctlc.com/wp-content/themes/qctlc/dist/images/email-template-images/email-icon.png"
                        style={{
                          marginTop: "-5px",
                          marginRight: "5px",
                          width: "50px",
                        }}
                        alt="Email Icon"
                      />
                      <a href="mailto:info@qctlc.com">info@qctlc.com</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    style={{
                      padding: "12px 15px",
                      textAlign: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#fff",
                      background: "#023962",
                    }}
                  >
                    Copyright © 2024 by QCTLC All Rights Reserved.
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default PrintPass;
