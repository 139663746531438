import React, { useState } from "react";
import { images } from "../../../actions/customFn";
import { forgetPassword } from "../../../Redux/Action/authAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ForgotPasswordModal = ({closeLoginModal , setSignupPart}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid email format.";
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(forgetPassword({ email, navigate , closeLoginModal , setSignupPart }));
    }
  };

  return (
    <div className="login_rightcontentpart forgot_paswordpart">
      <h2>Forgot password ?</h2>
      <img src={images["forgot-img.png"]} alt="" />
      <p>
        Please enter your registered email address we will get back to you with
        the reset password link and confirmation OTP thanks
      </p>

      <form onSubmit={handleSubmit}>
        <div className="inputall_main">
          <label>Email </label>
          <input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your registered email  address"
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
        </div>

        <div className="login_buttonmain">
          <button className="button_darkmain">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordModal;
