import React, { useState } from "react";
import "./buyonlinehere.scss";
import { images } from "../../../actions/customFn";
import { useNavigate } from "react-router-dom";
import { successMsg } from "../../../services/customeFn";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { format } from "date-fns";
import LoginModal from "../../Modals/LoginModal/LoginModal";

const BuyOnlineHere = ({ data }) => {
  const navigate = useNavigate();

  const { isAuthenticated, customerDetails } = useSelector(
    (state) => state.auth
  );

  const trackin_passes = data?.trackin_passes || [];
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});

  const handleInputChange = (e, passId) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [passId]: {
        ...formData[passId],
        [name]: value,
      },
    });
  };

  const validateForm = (passId) => {
    const errors = {};

    if (!formData[passId]?.start_date) {
      errors.start_date = "Date of visit is required.";
    }

    if (!formData[passId]?.quantity) {
      errors.quantity = "Number of people is required";
    }

    if (formData[passId]?.quantity <= 0) {
      errors.quantity = "Please enter a valid number of people.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [passId]: errors,
    }));

    return Object.keys(errors).length === 0;
  };

  const redirect = (passId, value) => {
    if (validateForm(passId)) {
      if (isAuthenticated) {
        if (customerDetails?.type == "Vendor") {
          // openLoginModal();
          navigate("/buypass/add", {
            state: {
              number_of_people: formData[passId]?.quantity,
              date_of_visit: formData[passId]?.start_date,
              pass_id: value["id"],
              from: "/buypass/add",
            },
          });
        } else {
          navigate("/checkout", {
            state: {
              number_of_people: formData[passId]?.quantity,
              date_of_visit: formData[passId]?.start_date,
              pass_id: value["id"],
              from: "/checkout",
            },
          });
        }
      } else {
        // openLoginModal();
        setLoginModal(true);
        successMsg("Please log in to continue.");
        navigate("#", {
          state: {
            number_of_people: formData[passId]?.quantity,
            date_of_visit: formData[passId]?.start_date,
            pass_id: value["id"],
          },
        });
      }
    }
  };

  const [loginmodal, setLoginModal] = useState(false);

  const openLoginModal = () => {
    setLoginModal(true);
  };

  const closeLoginModal = () => {
    setLoginModal(false);
  };

  return (
    <div className="buyonline_heresection">
      <div className="container">
        <div className="buyNowSection_title">
          <h3>{data?.buy_online_title}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: data?.buy_online_description }}
          ></p>
          <h6>{data?.buy_online_sub_title}</h6>
        </div>

        <div className="buyonline_cardsflex">
          {trackin_passes.map((value, index) => {
            const passId = `pass_${index}`;
            return (
              <div className="buycard_box1" key={passId}>
                <div className="buy_pass_area">
                  <img
                    className="ticket_img"
                    src={images["pass_img1.png"]}
                    alt=""
                  />
                  <div className="pass_price">{value.price}</div>

                  <div className="pass_title">{value.title}</div>
                  <div className="pass_content">{value.description}</div>

                  <form action="" method="post" target="">
                    <div
                      className="form-group"
                      id={`Input-pass_issue_date-start-${passId}`}
                    >
                      <label> Date of visit </label>

                      <Flatpickr
                        name="start_date"
                        className="form-control"
                        value={formData[passId]?.start_date || ""}
                        options={{
                          disableMobile: true,
                          minDate: "today", // Disable past dates
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(date) => {
                          handleInputChange(
                            {
                              target: {
                                name: "start_date",
                                value: format(date[0], "yyyy-MM-dd"),
                              },
                            },
                            passId
                          );
                        }}
                      />
                      {errors[passId]?.start_date && (
                        <div className="error-message">
                          {errors[passId].start_date}
                        </div>
                      )}
                      <span className="help-block"></span>
                    </div>

                    <div className="form-group" id={`Input-quantity-${passId}`}>
                      <label>Number of people</label>
                      <input
                        type="number"
                        name="quantity"
                        min="1"
                        required=""
                        className="form-control"
                        onChange={(e) => handleInputChange(e, passId)}
                      />
                      {errors[passId]?.quantity && (
                        <div className="error-message">
                          {errors[passId].quantity}
                        </div>
                      )}
                      <span className="help-block"></span>
                    </div>
                  </form>

                  
                  {customerDetails?.type  == "Websitevendor" ? (
                    <>
                    </>
                  ) : (
                    <button
                    onClick={() => redirect(passId, value)}
                    className=  "buy_now_btn"
                  >
                    Buy Now
                  </button>
                  )}
                  
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <LoginModal loginmodal={loginmodal} closeLoginModal={closeLoginModal} fromBuyPass ={true} />
    </div>
  );
};

export default BuyOnlineHere;
