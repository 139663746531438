import React, { useEffect, useState } from "react";
import { useGetCustomerListMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo } from "../../../services/customeFn";
import "./CheckOutForm.scss";
import { images } from "../../../actions/customFn";
import { useSelector } from "react-redux";
import { Select, Radio, Spin, Alert } from "antd";
const { Option } = Select;

const CheckOutForm = ({
  formdata,
  setFormdata,
  errors,
  validateFormData, 
}) => {


  const handleSelectChange = (field, value) => {
    setFormdata((prev) => ({ ...prev, [field]: value }));
    validateFormData();
  };


  return (
    <div className="checkout_formsectionmain">
      
      <div className="mb-4 mt-2">
        <h4>Select Your  Tracking Type</h4>
        <div className="custom-select-wrapper">
          <Select
            value={formdata.tracking_type}
            onChange={(value) => handleSelectChange("tracking_type", value)}
            style={{ width: "100%" }}
          >
            <option value="Cycling">Cycling</option>
            <option value="Walking">Walking</option>
          </Select>
          <span className="custom-arrow"></span>
        </div>

        {errors && errors.customer && (
          <div className="error-message">{errors.customer}</div>
        )}
      </div>

      <h4 className="mt-4">Payment Method</h4>
      <div className="checkpayment_sections">
        <div className="payment_choosen">
          <input
            type="radio"
            id="paypal"
            name="payment"
            value="paypal"
            onChange={(e) => handleSelectChange("payment_method", e.target.value)}
            checked={formdata.payment_method === "paypal"}
          />
          <label htmlFor="paypal">
            <img src={images["paypal1.png"]} alt="" />{" "}
            <img src={images["visa_payimg.png"]} alt="" />{" "}
          </label>
        </div>

        {/* <div className="debitcard_choosen">
          <input
            type="radio"
            id="debit"
            name="payment"
            value="debit"
            onChange={(e) => handleSelectChange("payment_method", e.target.value)}
            checked={formdata.payment_method === "debit"}
          />
          <label htmlFor="debit">Credit or debit card</label>
        </div> */}
      </div>
    </div>
  );
};

export default CheckOutForm;
