import React from "react";
import "./accommodations.scss";
import { NavLink } from "react-router-dom";

const Accommodations = ({ data }) => {
  const page_accommodations_value = data?.page_accommodations_value || [];
  const page_cooks_value = data?.page_cooks_value || [];
  const page_kayak_and_mountain_value =
    data?.page_kayak_and_mountain_value || [];
  const page_guided_value = data?.page_guided_value || [];
  const page_guided_array = Object.values(page_guided_value);

  const accomodation_data = [
    {
      list_points: [
        page_accommodations_value[0]?.accommodations || "",
        page_accommodations_value[1]?.accommodations || "",
        page_accommodations_value[2]?.accommodations || "",
      ].filter(Boolean),
    },
    {
      list_points: [
        page_accommodations_value[3]?.accommodations || "",
        page_accommodations_value[4]?.accommodations || "",
      ].filter(Boolean),
    },
    {
      list_points: [
        page_accommodations_value[5]?.accommodations || "",
        page_accommodations_value[6]?.accommodations || "",
      ].filter(Boolean),
    },
    {
      list_points: [
        page_accommodations_value[7]?.accommodations || "",
        page_accommodations_value[8]?.accommodations || "",
      ].filter(Boolean),
    },
  ];

  return (
    <div className="accomodations_mainsection">
      <div className="accommodation_section">
        <div className="container">
          <div className="accommodation_title">
            <h3>Accommodations</h3>
          </div>

          <div className="row">
            {accomodation_data.map((value, index) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
                  key={index}
                >
                  <div className="accommodations_Listcontain">
                    <ul>
                      {value.list_points.map((v, i) => {
                        return (
                          <li>
                            <NavLink to={"#"}>{v}</NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="inner_accommodationsList">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="listAccommodation">
                <h3 className="widget-title">{data?.cook_heading}</h3>
                <div className="accommodations_Listcontain">
                  <ul>
                    {page_cooks_value.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={"#"}>{v.cooks}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="listAccommodation">
                <h3 className="widget-title">
                  {data?.kayak_and_mountain_heading}
                </h3>
                <div className="accommodations_Listcontain">
                  <ul>
                    {page_kayak_and_mountain_value.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={"#"}>{v.kayak_and_mountain}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="listAccommodation">
                <h3 className="widget-title">{data?.guided_heading}</h3>
                <div className="accommodations_Listcontain">
                  <ul>
                    {page_guided_array.map((v, i) => {
                      return (
                        <li>
                          <NavLink to={"#"}>{v.guided}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accommodations;
