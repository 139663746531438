import React from "react";
import "./informationtext.scss";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const InformationText = ({ data }) => {
  return (
    <div className="information-text-section">
      <div
        className="information-bg-mian"
        style={{
          backgroundImage: `url(${images["mountain_img.png"]})`,
        }}
      >
        <div className="container">
          <div className="inner-grid-new">
            <div className="wd-info-50">
              <div className="text-data-grid">
                <div className="image-main">
                  <img src={data?.department_image} alt="" />
                </div>
                <div className="text-new">
                  <h2>{data?.department_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.department_description,
                    }}
                  ></p>
                  <div className="btn-inner">
                    <a
                      href={data?.department_url}
                      target="_blank"
                      className="btn-common"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="wd-info-50">
              <div className="text-data-grid">
                <div className="image-main">
                  <img src={data?.destination_image} alt="" />
                </div>
                <div className="text-new">
                  <h2>{data?.destination_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data?.destination_description,
                    }}
                  ></p>
                  <div className="btn-inner">
                    <a
                      href={data?.destination_url}
                      target="_blank"
                      className="btn-common"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationText;
