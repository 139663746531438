import React from "react";
import "./imagecard.scss";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const ImageCard = ({ data }) => {
  ;

  return (
    <div className="image-card-main">
      <div className="container">
        <div className="wd-info-grid">
          <div className="card-all-data wd-info-25">
            <div
              className="card-bg-img"
              style={{
                backgroundImage: `url(${data.title_image1})`,
              }}
            >
              <div className="icon-inner">
                {/* <img src={value.icon} alt="" /> */}
              </div>
              <div className="text-info">
                <h3>{data.title1}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.title_description1 }}
                ></p>
              </div>

              <div className="arrow_angle_btn">
                <NavLink to={"/buy-passes"}>
                  <img src={images["icon-a-link.png"]} alt="" />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card-all-data wd-info-25">
            <div
              className="card-bg-img"
              style={{
                backgroundImage: `url(${data.title_image2})`,
              }}
            >
              <div className="icon-inner">
                {/* <img src={value.icon} alt="" /> */}
              </div>
              <div className="text-info">
                <h3>{data.title2}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.title_description2 }}
                ></p>
              </div>

              <div className="arrow_angle_btn">
                <NavLink to={"/promotion"}>
                  <img src={images["icon-a-link.png"]} alt="" />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card-all-data wd-info-25">
            <div
              className="card-bg-img"
              style={{
                backgroundImage: `url(${data.title_image3})`,
              }}
            >
              <div className="icon-inner">
                {/* <img src={value.icon} alt="" /> */}
              </div>
              <div className="text-info">
                <h3>{data.title3}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.title_description3 }}
                ></p>
              </div>

              <div className="arrow_angle_btn">
                <NavLink to={"/track-enhancements"}>
                  <img src={images["icon-a-link.png"]} alt="" />
                </NavLink>
              </div>
            </div>
          </div>

          <div className="card-all-data wd-info-25">
            <div
              className="card-bg-img"
              style={{
                backgroundImage: `url(${data.title_image4})`,
              }}
            >
              <div className="icon-inner">
                {/* <img src={value.icon} alt="" /> */}
              </div>
              <div className="text-info">
                <h3>{data.title4}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.title_description4 }}
                ></p>
              </div>

              <div className="arrow_angle_btn">
                <NavLink to={"/qct-history"}>
                  <img src={images["icon-a-link.png"]} alt="" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
