import React, { useEffect } from "react";
import { CouponCard, Header, Sidebar } from "../components";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const Coupon = () => {



  
  return (
    <>
    <PageTitleHelmet />
    <div className="coupon-data-main">
      <div className="header-pages">
        <Header />
      </div>

      <div className="container">
        <div className="slide-bar-grid">
          <div className="left-bar-data">
            <Sidebar />
          </div>
          <div className="right-bar-data">
            <CouponCard />
          </div>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default Coupon;
